import {
  type NotificationsQuery,
  useNotificationsQuery,
} from '@/common/graphql/notifications.generated';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

export type NotificationContextProps = {
  newRequestCount: number;
  newChatMessageCount: number;
  addNewChatMessageCount: (value: number) => void;
};

export type NotificationProps = {
  children: ReactNode;
};

const NotificationContext = createContext<NotificationContextProps>({
  newRequestCount: 0,
  newChatMessageCount: 0,
  addNewChatMessageCount: () => undefined,
});

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }: NotificationProps) => {
  const [newChatMessageCount, setNewChatMessageCount] = useState<number>(0);

  const onCompletedNotificationsQuery = (data: NotificationsQuery) => {
    const { newChatMessageCount } = data.notifications;
    setNewChatMessageCount(newChatMessageCount);
  };
  const { data } = useNotificationsQuery({
    onCompleted: onCompletedNotificationsQuery,
  });

  const addNewChatMessageCount = useCallback((value: number) => {
    setNewChatMessageCount((prev) => prev + value);
  }, []);

  useEffect(() => {
    if (navigator.setAppBadge) {
      if (newChatMessageCount > 0) {
        navigator.setAppBadge(newChatMessageCount);
      } else {
        navigator.clearAppBadge();
      }
    }
  }, [newChatMessageCount]);

  return (
    <NotificationContext.Provider
      value={{
        newRequestCount: data?.notifications.newRequestCount ?? 0,
        newChatMessageCount,
        addNewChatMessageCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
